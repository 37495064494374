import axios from "axios";
import { API } from "../../constants";

export const authSignInPost = async ({ identifier, password }) => {
  try {
    const url = `${API}/auth/signin`;
    const res = await axios.post(url, { identifier, password });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const authSignUpPost = async ({ name, role, username, password }) => {
  try {
    const url = `${API}/auth/signup`;
    const res = await axios.post(url, { name, role, username, password });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
