import axios from "axios";
import { API } from "../../constants";
import { toast } from "react-toastify";
export const ordersGet = async (findBy, token) => {
  try {
    const url = `${API}/orders${findBy}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const orderGet = async (id, token) => {
  try {
    const url = `${API}/order/${id}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const orderPost = async (body, token) => {
  try {
    const url = `${API}/order/create`;
    const res = await axios.post(url, { ...body });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const orderPut = async (id, body, token) => {
  try {
    const url = `${API}/order/update`;
    const res = await toast.promise(axios.put(url, { id: id, data: body }), {
      pending: "ກຳລັງແກ້ໄຂຫ້ອງ...",
      success: {
        render() {
          return "ແກ້ໄຂຫ້ອງສຳເລັດ 👌";
        },
        autoClose: 1000,
      },
      error: "Error ບໍ່ສາມາດແກ້ໄຂຫ້ອງໄດ້ 🤯",
    });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const orderDelete = async (id, token) => {
  try {
    const url = `${API}/order/delete`;
    const res = await toast.promise(
      axios.delete(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        data: { id: id },
      }),
      {
        pending: "ກຳລັງລົບຫ້ອງ...",
        success: {
          render() {
            return "ລົບຫ້ອງສຳເລັດ 👌";
          },
          autoClose: 1000,
        },
        error: "Error ບໍ່ສາມາດລົບຫ້ອງໄດ້ 🤯",
      }
    );
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
export const orderCountGet = async (findBy, token) => {
  try {
    const url = `${API}/order/count${findBy}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
