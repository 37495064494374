import { useEffect, useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
// import { hostelsGet } from "../../services/v1/hostel.service";

export const HostelContext = ({ loadingContext }) => {
  const [isHostelsLoading, setIsHostelsLoading] = useState(false);
  const [hostels, setHostels] = useState([]);
  const [findBy, setFindBy] = useState("?");
  const [searchHostel, setSearchHostel] = useState();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);

  // useEffect
  useEffect(() => {
    // FetchHostelsData();
  }, [findBy]);
  useEffect(() => {
    let _findBy = "?";
    _findBy += `skip=${skip}`;
    _findBy += `&limit=${limit}`;
    if (
      searchHostel != "" &&
      searchHostel != null &&
      searchHostel != undefined
    ) {
      _findBy += `&search=${searchHostel}`;
    }
    setFindBy(_findBy);
  }, [limit, skip, searchHostel]);
  useEffect(() => {
    if (page > 0) {
      setSkip(50 * (page - 1));
      setLimit(50 * page);
    }
  }, [page]);
  // function
  // const FetchHostelsData = async () => {
  //   loadingContext.setIsLoading(true);
  //   const _hostels = await hostelsGet(findBy);
  //   // setHostels(_hostels);
  //   loadingContext.setIsLoading(false);
  // };
  return {
    isHostelsLoading,
    setIsHostelsLoading,
    hostels,
    setHostels,
    // FetchHostelsData,
    page,
    setPage,
    searchHostel,
    setSearchHostel,
  };
};
