import React, { useState } from "react";
import { Button } from "react-bootstrap";

function FileDropzone() {
  const [files, setFiles] = useState([]);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow a drop
  };

  return (
    <div
      style={{
        border: "2px dashed #ccc",
        borderRadius: 10,
        backgroundColor: "#f9f9f9",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button>Download file format</Button>
        <p style={{ fontSize: 18, fontWeight: 700 }}>
          Drag and drop files (*.csv) here for upload file
        </p>
      </div>
      {files.length === 0 ? (
        ""
      ) : (
        <ul>
          {files.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default FileDropzone;
