import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Form, Button, Card, ListGroup, Breadcrumb } from "react-bootstrap";

// data
// eslint-disable-next-line
import { faker } from "@faker-js/faker";
import { useNavigate, useParams } from "react-router-dom";
import { useProvider } from "../../providers";
import {
  accountGet,
  accountPost,
  accountPut,
} from "../../services/v1/account.service";
import AcountForm from "../../components/form/AcountForm";

export default function AccountUpdatePage() {
  const navigate = useNavigate();
  const { accountId } = useParams();
  // state ----------------------------------------------------------------------------
  const [accountData, setAccountData] = useState();
  // provider ----------------------------------------------------------------------------
  const { token } = useProvider();

  // useEffect ----------------------------------------------------------------------------
  useEffect(() => {
    if (accountId) {
      getData();
    }
  }, [accountId]);
  // function ----------------------------------------------------------------------------
  const getData = async () => {
    const data = await accountGet(accountId, token);
    console.log("account data", data);
    setAccountData(data);
  };
  const handleUpdate = async (body) => {
    const data = await accountPut(body?._id, body, token);
    if (!data?.error) {
      navigate("/account", { replace: true });
    }
  };
  return (
    <div style={{ padding: 20 }}>
      <Breadcrumb>
        <Breadcrumb.Item>มินเนี่ยน</Breadcrumb.Item>
        <Breadcrumb.Item active>แก้ไขมินเนี่ยน</Breadcrumb.Item>
      </Breadcrumb>
      <AcountForm defaultData={accountData} onSubmit={handleUpdate} />
    </div>
  );
}
