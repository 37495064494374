import axios from "axios";
import { API } from "../../constants";
import { toast } from "react-toastify";
export const accountsGet = async (findBy, token) => {
  try {
    const url = `${API}/accounts${findBy}`;
    const res = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const accountGet = async (id, token) => {
  try {
    const url = `${API}/account/${id}`;
    const res = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const accountPost = async (body, token) => {
  try {
    const url = `${API}/account/create`;
    const res = await axios.post(
      url,
      { ...body },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const accountPut = async (id, body, token) => {
  try {
    const url = `${API}/account/update`;
    const res = await toast.promise(
      axios.put(
        url,
        { id: id, data: body },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      ),
      {
        pending: "ກຳລັງແກ້ໄຂຫ້ອງ...",
        success: {
          render() {
            return "ແກ້ໄຂຫ້ອງສຳເລັດ 👌";
          },
          autoClose: 1000,
        },
        error: "Error ບໍ່ສາມາດແກ້ໄຂຫ້ອງໄດ້ 🤯",
      }
    );
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const accountDelete = async (id, token) => {
  try {
    const url = `${API}/account/delete`;
    const res = await toast.promise(
      axios.delete(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        data: { id: id },
      }),
      {
        pending: "ກຳລັງລົບຫ້ອງ...",
        success: {
          render() {
            return "ລົບຫ້ອງສຳເລັດ 👌";
          },
          autoClose: 1000,
        },
        error: "Error ບໍ່ສາມາດລົບຫ້ອງໄດ້ 🤯",
      }
    );
    return res.data;
  } catch (error) {
    console.error(await error.message);
    return { error: true };
  }
};
export const accountCountGet = async (findBy, token) => {
  try {
    const url = `${API}/account/count${findBy}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
