import axios from "axios";
import { API } from "../../constants";
export const billsGet = async (findBy, token) => {
  try {
    const url = `${API}/bills${findBy}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const billGet = async (id, token) => {
  try {
    const url = `${API}/bill/get/${id}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const billPost = async (body, token) => {
  try {
    const url = `${API}/bill/create`;
    const res = await axios.post(url, { ...body });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const billPut = async (id, body, token) => {
  try {
    const url = `${API}/bill/update`;
    const res = await axios.put(url, { id: id, data: body });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
export const billCountGet = async (findBy, token) => {
  try {
    const url = `${API}/bill/count${findBy}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return { error: true };
  }
};

export const checkOutPost = async (body, token) => {
  try {
    const url = `${API}/check-out`;
    const res = await axios.post(url, { ...body });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};

export const billRunPost = async (billId, token) => {
  try {
    const url = `${API}/bill/run/${billId}`;
    const res = await axios.post(url);
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
