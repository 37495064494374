import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layout
import MainLayout from "../layouts/MainLayout";

// page
import PAGE_404 from "../pages/404";

// routes
import PrivateRoute from "./PrivateRoute";

// import RoomRoutes from "./Room.routes";
import AuthRoutes from "./Auth.routes";
import MapRoutes from "./Map.routes";
import AccountRoutes from "./Account.routes";
import TriggerPage from "../pages/trigger/TriggerPage";
import TriggerRoutes from "./Trigger.routes";
import WorkRoutes from "./Work.routes";
import KeywordRoutes from "./Keyword.routes";
import LogRoutes from "./Log.routes";
// import SettingRoutes from "./Setting.routes";

function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <PrivateRoute>
          <MainLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "",
          element: <Navigate to={"/auth/login"} />,
        },
        MapRoutes,
        AccountRoutes,
        TriggerRoutes,
        WorkRoutes,
        KeywordRoutes,
        LogRoutes,
        // SettingRoutes,
      ],
    },
    {
      path: "/",
      element: <Navigate to={"/auth/login"} />,
    },
    AuthRoutes,
    {
      path: "*",
      element: <PAGE_404 />,
    },
  ]);
}

export default Router;
