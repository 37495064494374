import React from "react";
import { Outlet } from "react-router-dom";

// page
import AccountCreatePage from "../pages/account/AccountCreatePage";
import AccountPage from "../pages/account/AccountPage";
import AccountUpdatePage from "../pages/account/AccountUpdatePage";
import TriggerPage from "../pages/trigger/TriggerPage";

// eslint-disable-next-line
export default {
  path: "/trigger",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <TriggerPage />,
    },
    {
      path: "create",
      element: <AccountCreatePage />,
    },
    {
      path: "update/:accountId",
      element: <AccountUpdatePage />,
    },
  ],
};
