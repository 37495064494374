export const convertStatusAccount = (status) => {
  let result = status;
  switch (status) {
    case "STRONG":
      result = <span style={{ color: "green" }}>ໃຊ້ງານໄດ້</span>;
      break;
    case "DOWN":
      result = <span style={{ color: "red" }}>ໃຊ້ບໍ່ງານໄດ້</span>;
      break;
    case "WAIT_QC":
      result = <span style={{ color: "yellow" }}>ລໍຖ້າກວດ</span>;
      break;
    default:
    // return "ຍົກເລີກ";
  }
  return result;
};
