import React from "react";
import { Outlet } from "react-router-dom";

// page
import AccountCreatePage from "../pages/account/AccountCreatePage";
import AccountPage from "../pages/account/AccountPage";
import AccountUpdatePage from "../pages/account/AccountUpdatePage";
import WorkPage from "../pages/work/WorkPage";
import WorkCreatePage from "../pages/work/WorkCreatePage";
import WorkDetailPage from "../pages/work/WorkDetailPage";

// eslint-disable-next-line
export default {
  path: "/work",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <WorkPage />,
    },
    {
      path: "create",
      element: <WorkCreatePage />,
    },
    {
      path: "update/:accountId",
      element: <AccountUpdatePage />,
    },
    {
      path: "datail/:billId",
      element: <WorkDetailPage />,
    },
  ],
};
