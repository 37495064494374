import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useProvider } from "../../providers";
import { COLOR } from "../../constants";

const defaultCallback = () => {};
export default function ConfirmDeletePopup({
  open,
  onClose,
  onSubmit,
  callback = defaultCallback,
}) {
  // state

  // provider
  const { token, FetchHostelsData } = useProvider();
  // function
  const handleConfirmDelete = async () => {
    try {
      const data = await onSubmit();
      if (data.error) {
        return data;
      } else {
        callback();
        onClose();
      }
    } catch (error) {
      console.error(error);
      return { error: true };
    }
  };
  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", gap: 30, alignItems: "center" }}>
          <div>
            <MdDelete style={{ color: COLOR.PRIMARY, fontSize: 90 }} />
          </div>
          <div style={{ fontWeight: "bold", fontSize: 32 }}>ຍືນຍັນການລົບ!</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirmDelete}>ລົບ</Button>
      </Modal.Footer>
    </Modal>
  );
}
