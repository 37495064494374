import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Card } from "react-bootstrap";
import { FaMapPin, FaSave } from "react-icons/fa";
// import { hostelPost } from "../../services/v1/hostel.service";
import { useProvider } from "../../providers";
import { toast } from "react-toastify";
import HostelForm from "../form/HostelForm";

export default function AddHostelPopup({
  open,
  onClose,
  callback,
  defaultData,
}) {
  // state

  // provider
  const { token, FetchHostelsData } = useProvider();
  // function
  const handleAddHostel = async (formData) => {
    try {
      const _body = {
        ...formData,
      };
      // const data = await hostelPost(_body, token);
      // if (data.error) {
      //   return data;
      // } else {
      //   FetchHostelsData();
      //   onClose();
      // }
    } catch (error) {
      console.error(error);
      return { error: true };
    }
  };
  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>ເພີ່ມທີ່ພັກ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HostelForm
          defaultData={defaultData}
          onSubmit={handleAddHostel}
          callback={callback}
        />
      </Modal.Body>
    </Modal>
  );
}
