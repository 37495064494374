import React, { useEffect, useState } from "react";
import { Card, Button, Form, FloatingLabel } from "react-bootstrap";
import { FaMapPin, FaSave } from "react-icons/fa";
import UploadImageFreeImageHost from "../upload/UploadImageFreeImageHost";
const defaultCallback = () => {};
export default function WorkForm({
  onSubmit,
  defaultData,
  callback = defaultCallback,
}) {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    url: "",
    ...defaultData,
  });
  // useEffect
  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...defaultData }));
  }, [defaultData]);

  // function
  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(formData).then((e) => callback(e));
    setIsLoading(false);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: 10,
        flexDirection: "column",
      }}
    >
      <div>
        <Form.Label>ชื่อ</Form.Label>
        <Form.Control
          value={formData?.name}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, name: e.target.value }))
          }
        />
      </div>
      <div>
        <Form.Label>type</Form.Label>
        <Form.Select
          value={formData?.type}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, type: e.target.value }))
          }
        >
          <option value="">ເລືອກ type</option>
          <option value="LIKE_POST_FACEBOOK">LIKE_POST_FACEBOOK</option>
          <option value="AUTO_ADD_FRIEND_FACEBOOK">
            AUTO_ADD_FRIEND_FACEBOOK
          </option>
        </Form.Select>
      </div>
      <div>
        <Form.Label>url post</Form.Label>
        <Form.Control
          value={formData?.url}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, url: e.target.value }))
          }
        />
      </div>
      <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
        <FaSave /> ບັນທຶກ
      </Button>
    </div>
  );
}
