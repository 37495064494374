import React, { useState } from "react";
import axios from "axios";
// import { freeImageHostPost } from "../../services/v1/gateway.service";

var myHeaders = new Headers();
myHeaders.append("Cookie", "PHPSESSID=4j9b2uerpbjjrcsdf3pbu43qul");

var formdata = new FormData();
formdata.append(
  "image",
  "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII="
);

var requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: formdata,
  redirect: "follow",
};

fetch(
  "https://freeimage.host/api/1/upload?key=6d207e02198a847aa98d0a2a901485a5",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error("error", error));

const consoleFunction = (e) => {
  console.log(e);
};
const defaultCallback = () => {};
/**
 * ໃຊ້ service ຂອງ free image host
 * https://freeimage.host/
 *
 * @returns
 */
export default function UploadImageFreeImageHost({
  children,
  style,
  isLoading = consoleFunction,
  callback = defaultCallback,
}) {
  // state
  const [selectImage, setSelectImage] = useState();

  // function
  const handleUpload = async (event) => {
    try {
      setSelectImage(event.target.value);
      isLoading(true);

      const file = event.target.files[0];
      const image = await toBase64(file);

      // const upload = await freeImageHostPost({ image });
      // if (!upload?.error) {
      //   callback(upload);
      // }
    } catch (err) {
      isLoading(false);
      console.log(err);
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const _base64 = reader.result.split(",")[1];
        // console.log(_base64);
        resolve(_base64);
      };
      reader.onerror = reject;
    });

  return (
    <div>
      <input
        type="file"
        id="file-upload"
        value={selectImage}
        onChange={handleUpload}
        accept="image/png, image/jpeg, image/jpg"
        hidden
      />
      <label for="file-upload" style={style}>
        {children}
      </label>
    </div>
  );
}
