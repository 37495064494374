import React from "react";
import { WaveSpinner } from "react-spinners-kit";
import { useProvider } from "../providers";
import { COLOR } from "../constants";

export default function LoadingComponent() {
  const { isLoading } = useProvider();
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        height: "100dvh",
        backgroundColor: "rgba(255,255,255,0.2)",
        backdropFilter: "blur(10px)",
        // opacity: 0.2,
        zIndex: 9999,
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <WaveSpinner
        size={40}
        color={COLOR.PRIMARY}
        frontColor={COLOR.PRIMARY}
        backColor="#999999"
        loading={isLoading}
      />
      <div>ກຳລັງໂຫລດຂໍ້ມູນ...</div>
    </div>
  );
}
