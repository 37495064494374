import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Form, Button, Card, ListGroup, Breadcrumb } from "react-bootstrap";

// data
// eslint-disable-next-line
import { faker } from "@faker-js/faker";
import { useNavigate, useParams } from "react-router-dom";
import { useProvider } from "../../providers";
import { accountPost, accountsGet } from "../../services/v1/account.service";
import WorkForm from "../../components/form/WorkForm";
import { billGet, billPost, billRunPost } from "../../services/v1/bill.service";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import { orderPost, ordersGet } from "../../services/v1/order.service";

export default function WorkDetailPage() {
  const navigate = useNavigate();
  const { billId } = useParams();
  // state ----------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState();
  const [orders, setOrders] = useState();
  const [bill, setBill] = useState();
  // provider ----------------------------------------------------------------------------
  const { token } = useProvider();

  // useEffect ----------------------------------------------------------------------------
  useEffect(() => {
    FetchAcountsData();
    FetchBillData();
    FetchOrdersData();
  }, []);

  // function ----------------------------------------------------------------------------
  const FetchAcountsData = async () => {
    let findBy = "?";
    // if (searchInput) findBy += "search=" + searchInput + "&";
    // if (filterStatusInput) findBy += "status=" + filterStatusInput + "&";
    // if (filterTypeInput) findBy += "type=" + filterTypeInput + "&";
    const data = await accountsGet(findBy, token);
    if (!data?.error) {
      setAccounts(data);
    }
  };
  const FetchBillData = async () => {
    const data = await billGet(billId, token);
    if (!data?.error) {
      setBill(data);
    }
  };
  const FetchOrdersData = async () => {
    let findBy = "?";
    findBy += "billId=" + billId + "&";
    findBy += "p=accountId&";
    // if (searchInput) findBy += "search=" + searchInput + "&";
    // if (filterStatusInput) findBy += "status=" + filterStatusInput + "&";
    // if (filterTypeInput) findBy += "type=" + filterTypeInput + "&";
    const data = await ordersGet(findBy, token);
    if (!data?.error) {
      setOrders(data);
    }
  };
  const handleCreateOrder = async (accountId) => {
    const _body = {
      billId: billId,
      code: bill?.code,
      accountId: accountId,
    };
    const data = await orderPost(_body, token);
    if (!data?.error) {
      FetchOrdersData();
    }
  };
  const handleRun = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await billRunPost(billId);
      await FetchBillData();
      setIsLoading(false);
    }
  };
  return (
    <div style={{ padding: 20 }}>
      <Breadcrumb>
        <Breadcrumb.Item>มินเนี่ยน</Breadcrumb.Item>
        <Breadcrumb.Item active>เพี่มมินเนี่ยน</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        style={{
          padding: 10,
          display: "flex",
          gap: 10,
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div>name: {bill?.name}</div>
        <div>type: {bill?.type}</div>
        <div>accounts total: {bill?.total}</div>
        <div>
          link:{" "}
          <a href={bill?.url} target="_blank">
            {bill?.url}
          </a>
        </div>
        <div>
          <Button disabled={!bill?.active} onClick={handleRun}>
            Start
          </Button>
        </div>
      </Card>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}>
        <div>
          <Card
            style={{
              padding: 10,
              display: "flex",
              gap: 10,
              flexDirection: "row",
              marginBottom: 10,
            }}
          >
            <div style={{ display: "flex", gap: 10 }}>
              <Form.Control
                type="text"
                placeholder="ຄົ້ນຫາ..."
                // value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
              />

              <Button variant="primary">
                <FaSearch />
              </Button>
            </div>
            <Form.Select
              style={{ width: 150 }}
              // value={filterStatusInput}
              // onChange={(e) => setFilterStatusInput(e.target.value)}
            >
              <option value="">status</option>
              <option value="WAIT_QC">WAIT_QC</option>
              <option value="STRONG">STRONG</option>
              <option value="DOWN">DOWN</option>
            </Form.Select>
            <Form.Select
              style={{ width: 150 }}
              // value={filterTypeInput}
              // onChange={(e) => setFilterTypeInput(e.target.value)}
            >
              <option value="">type</option>
              <option value="facebook">facebook</option>
              <option value="google">google</option>
            </Form.Select>
          </Card>
          <Table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>mail</th>
                <th>status</th>
                <th>type</th>
                <th>tool</th>
              </tr>
            </thead>
            <tbody>
              {accounts?.map((e, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{e?.firstname + " " + e?.lastname}</td>
                  <td>{e?.email}</td>
                  <td>{e.status}</td>
                  <td>{e?.type}</td>
                  <td>
                    <Button onClick={() => handleCreateOrder(e?._id)}>+</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div>
          <Card
            style={{
              padding: 10,
              display: "flex",
              gap: 10,
              flexDirection: "row",
              marginBottom: 10,
            }}
          >
            <div style={{ display: "flex", gap: 10 }}>
              <Form.Control
                type="text"
                placeholder="ຄົ້ນຫາ..."
                // value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
              />

              <Button variant="primary">
                <FaSearch />
              </Button>
            </div>
            <Form.Select
              style={{ width: 150 }}
              // value={filterStatusInput}
              // onChange={(e) => setFilterStatusInput(e.target.value)}
            >
              <option value="">status</option>
              <option value="WAIT_QC">WAIT_QC</option>
              <option value="STRONG">STRONG</option>
              <option value="DOWN">DOWN</option>
            </Form.Select>
            <Form.Select
              style={{ width: 150 }}
              // value={filterTypeInput}
              // onChange={(e) => setFilterTypeInput(e.target.value)}
            >
              <option value="">type</option>
              <option value="facebook">facebook</option>
              <option value="google">google</option>
            </Form.Select>
          </Card>
          <Table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>mail</th>
                <th>status</th>
                <th>type</th>
                <th>tool</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((e, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>
                    {e?.accountId?.firstname + " " + e?.accountId?.lastname}
                  </td>
                  <td>{e?.accountId?.email}</td>
                  <td>{e?.accountId?.status}</td>
                  <td>{e?.accountId?.type}</td>
                  <td>
                    <Button>+</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

const Table = styled("table")({
  width: "100%",
  ["td,th"]: {
    border: " 1px solid #231f20",
    padding: 8,
  },
  ["tr:nth-child(even)"]: { backgroundColor: "#f2f2f2" },
  ["tr:hover"]: { backgroundColor: "#949699" },
  th: {
    paddingTop: 12,
    paddingBottom: 12,
    textAlign: "left",
    backgroundColor: "#fce029",
    color: "#231f20",
  },
});
