import { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

export const AuthContext = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useLocalStorage("isLogin");
  const [token, setToken] = useLocalStorage("token");

  const logout = () => {
    setToken("");
    setIsLogin(false);
    window.history.replaceState(null, null, "/");
    navigate("/", { replace: true });
  };

  return { isLogin, setIsLogin, token, setToken, logout };
};
