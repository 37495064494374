import React from "react";
import Box from "../components/Box";
import { Outlet } from "react-router-dom";
import backgroundLogin from "../assets/background-login.jpg";

export default function SecondaryLayout({ children }) {
  return (
    <div
      style={{
        // marginLeft: { md: 65 },
        backgroundImage: `url("${backgroundLogin}")`,
        backgroundRepeat: "no-repeat, repeat",
        height: "100vh",
        backgroundSize: "cover",
      }}
    >
      <Outlet />
    </div>
  );
}
