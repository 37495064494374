// ກວດສອບ domain ປະຈຸບັນ ຖ້າເປັນ domain ຂອງ production ແລ້ວ api endpoint ກໍ່ຈະເປັນ production
const production_domain = "minion.nonlub.com"; // Production domain
const now_domain = window.location.hostname; // Domain ປະຈຸບັນ
const isProduction = production_domain == now_domain; // ສະຖານະເປັນ production

// API
const API_PRO = "https://minion-api.nonlub.com"; // Production
const API_DEV = "http://localhost:7070"; // Localhost

const RABLIT_API_PRO =
  "https://queue.nonlub.com/api/queues/%2F/VHOST_THERDY_1?page_size=1&page=1";
const RABLIT_API_DEV =
  "https://queue.nonlub.com/api/queues/%2F/VHOST_THERDY_1?page_size=1&page=1";

// ສົ່ງອອກ API endpoint
export default isProduction ? API_PRO : API_DEV;
