import { useState, useEffect } from "react";
// import { roomsGet } from "../../services/v1/room.service";

export const RoomContext = ({ hostelContext }) => {
  const [isRoomsLoading, setRoomsLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [isRoomSelectLoading, setIsRoomSelectLoading] = useState(false);
  const [roomSelect, setRoomSelect] = useState();

  // function ----------------------------------------------------------------------------
  const getRooms = async () => {
    try {
      // verify
      if (!hostelContext?.hostel?._id) throw new Error("HOSTEL_ID EMPTY");

      setRoomsLoading(true);
      let findby = "?";
      findby += `hostel_id=${hostelContext?.hostel?._id}`;
      // const data = await roomsGet(findby);
      // if (!data.error) {
      //   setRooms(data);
      // }
      setRoomsLoading(false);
    } catch (err) {
      setRoomsLoading(false);
    }
  };

  // useEffect ----------------------------------------------------------------------------
  useEffect(() => {
    getRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostelContext?.hostel?._id]);
  return {
    isRoomsLoading,
    setRoomsLoading,
    rooms,
    setRooms,
    isRoomSelectLoading,
    setIsRoomSelectLoading,
    roomSelect,
    setRoomSelect,
    // function ----------------------------------------------------------------------------
    getRooms,
  };
};
