import React from "react";
import Routes from "./routes";
import { ThemeProvider } from "styled-components";
import { Provider } from "./providers";
import { ToastContainer /* toast */ } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingComponent from "./components/LoadingComponent";

const theme = {
  xl: "@media screen and (min-width: 1536px)",
  lg: "@media screen and (min-width: 1200px)",
  md: "@media screen and (min-width: 900px)",
  sm: "@media screen and (min-width: 600px)",
  xs: "@media screen and (min-width: 0px)",
};

function App() {
  return (
    <Provider>
      <ThemeProvider theme={theme}>
        <Routes />
        <ToastContainer />
        <LoadingComponent />
      </ThemeProvider>
    </Provider>
  );
}
export default App;
