import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Form, Button, Card, ListGroup, Breadcrumb } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useProvider } from "../../providers";
import AddHostelPopup from "../../components/popup/AddHostelPopup";
import { COLOR } from "../../constants";
import {
  accountDelete,
  accountPut,
  accountsGet,
} from "../../services/v1/account.service";
import gmailImage from "../../assets/gmail-image.jpg";
// data
// eslint-disable-next-line
import { faker } from "@faker-js/faker";
import moment from "moment/moment";
import { qcLoginFacebookPost } from "../../services/v1/qc.service";
import { GrPowerReset } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import { MdOutlineAdd } from "react-icons/md";
// image
import imageEmptyProfile from "../../assets/empty-profile.webp";
import ConfirmDeletePopup from "../../components/popup/ConfirmDeletePopup";
import TableComponent from "../../components/TableComponent";

export default function TriggerPage() {
  const navigate = useNavigate();
  // state
  const [popup, setPopup] = useState();
  const [selectAccount, setSelectAccount] = useState();
  const [accounts, setAccounts] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [filterStatusInput, setFilterStatusInput] = useState("");
  const [filterTypeInput, setFilterTypeInput] = useState("");
  // provider ----------------------------------------------------------------------------
  const {} = useProvider();
  const { token } = useProvider();

  // useEffect ----------------------------------------------------------------------------
  useEffect(() => {
    FetchAcountsData();
  }, []);

  // function ----------------------------------------------------------------------------
  const FetchAcountsData = async () => {
    let findBy = "?";
    if (searchInput) findBy += "search=" + searchInput + "&";
    if (filterStatusInput) findBy += "status=" + filterStatusInput + "&";
    if (filterTypeInput) findBy += "type=" + filterTypeInput + "&";
    const data = await accountsGet(findBy, token);
    if (!data?.error) {
      setAccounts(data);
    }
  };
  const handleLoginFacebook = async (accountId) => {
    qcLoginFacebookPost(accountId, token);
  };
  const handleDelete = async () => {
    await accountDelete(selectAccount?._id, token);
    await FetchAcountsData();
    setSelectAccount();
    setPopup();
  };
  const handleUpdateAccount = async (accountId, status) => {
    await accountPut(accountId, { status: status }, token);
    await FetchAcountsData();
  };
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 20,
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item active>มินเนี่ยน</Breadcrumb.Item>
        </Breadcrumb>
        <Card
          style={{
            padding: 10,
            display: "flex",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            <Form.Control
              type="text"
              placeholder="ຄົ້ນຫາ..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />

            <Button variant="primary" onClick={() => FetchAcountsData()}>
              <FaSearch />
            </Button>
          </div>
          <Form.Select
            style={{ width: 150 }}
            value={filterStatusInput}
            onChange={(e) => setFilterStatusInput(e.target.value)}
          >
            <option value="">status</option>
            <option value="WAIT_QC">WAIT_QC</option>
            <option value="STRONG">STRONG</option>
            <option value="DOWN">DOWN</option>
          </Form.Select>
          <Form.Select
            style={{ width: 150 }}
            value={filterTypeInput}
            onChange={(e) => setFilterTypeInput(e.target.value)}
          >
            <option value="">type</option>
            <option value="facebook">facebook</option>
            <option value="google">google</option>
          </Form.Select>

          <Button
            variant="primary"
            onClick={() => {
              setSearchInput("");
              setFilterTypeInput("");
              setFilterStatusInput("");
            }}
          >
            <IconGrStyle>
              <GrPowerReset />
            </IconGrStyle>
          </Button>
          <div style={{ flex: 1 }} />
          <Button onClick={() => navigate("/account/create")}>
            <MdOutlineAdd /> เพี่มมินเนี่ยน
          </Button>
        </Card>
        <TableComponent>
          <thead>
            <tr>
              <th>No.</th>
              <th>Image</th>
              <th>Name</th>
              <th>mail</th>
              <th>status</th>
              <th>last_active</th>
              <th>type</th>
              <th>tool</th>
            </tr>
          </thead>
          <tbody>
            {accounts?.map((e, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>
                  <div>
                    <img
                      src={imageEmptyProfile}
                      alt=""
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "conver",
                      }}
                    />
                  </div>
                </td>
                <td>{e?.firstname + " " + e?.lastname}</td>
                <td>{e?.email}</td>
                <td>
                  {e.status}
                  <Button
                    style={{
                      display:
                        e.status != "STRONG" && e.status != "DOWN"
                          ? "inline-block"
                          : "none",
                    }}
                    onClick={() => handleUpdateAccount(e?._id, "STRONG")}
                  >
                    ສຳເລັດ
                  </Button>
                  <Button
                    variant="secondary"
                    style={{
                      display:
                        e.status != "STRONG" && e.status != "DOWN"
                          ? "inline-block"
                          : "none",
                    }}
                    onClick={() => handleUpdateAccount(e?._id, "DOWN")}
                  >
                    fix
                  </Button>
                </td>
                <td>{moment(e?.lastActive).format("YYYY/MM/DD HH:ss")}</td>
                <td>{e?.type}</td>
                <td>
                  <Button onClick={() => handleLoginFacebook(e?._id)}>
                    Login
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectAccount(e);
                      setPopup({ ConfirmDeletePopup: true });
                    }}
                  >
                    delete
                  </Button>
                  <Button onClick={() => navigate(`/account/update/${e?._id}`)}>
                    update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </div>
      {/* popup */}
      <AddHostelPopup open={popup?.AddHostelPopup} onClose={() => setPopup()} />
      <ConfirmDeletePopup
        open={popup?.ConfirmDeletePopup}
        onClose={() => setPopup()}
        onSubmit={handleDelete}
      />
    </>
  );
}

const IconGrStyle = styled("div")({
  path: {
    stroke: "#fff",
  },
});
const CardItemCompoment = styled(Card)({
  display: "flex",
  flexDirection: "row",
  gap: 10,
  padding: 10,
  cursor: "pointer",
  boxShadow: "3px 3px 10px 1px rgba(0,0,0,0.1)",
  ["&:hover"]: {
    backgroundColor: "rgba(26,57,80,0.1)",
    outline: `1px solid ${COLOR.PRIMARY}`,
  },
});
