import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { authSignInPost } from "../../services/v1/auth.service";
import { useProvider } from "../../providers";
import { useNavigate } from "react-router-dom";
// import { myHostelPost } from "../../services/v1/hostel.service";

export default function LoginPage() {
  const navigate = useNavigate();
  // state ----------------------------------------------------------------------------
  const [agree, setAgree] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");

  // provider ----------------------------------------------------------------------------
  const { token, setToken } = useProvider();
  const { isLogin, setIsLogin } = useProvider();

  // function ----------------------------------------------------------------------------
  const handleLogin = async () => {
    try {
      const resualt = await authSignInPost({ identifier, password });
      if (resualt.jwt) {
        setIsLogin(true);
        setToken(resualt.jwt);
        // const hostel = await myHostelPost(resualt.jwt);
        // if (!hostel) throw new Error("HOSTEL_EMPTY");
        navigate("/account");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: 400,
        margin: "0 auto",
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <Card style={{ width: "100%" }}>
        <Card.Body>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 32,
              display: "flex",
              justifyContent: "center",
            }}
          >
            Login
          </div>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <div
              className="mb-3"
              style={{ display: "flex", gap: 10, alignItems: "center" }}
            >
              <Form.Check
                type="checkbox"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
              />
              <span htmlFor="policy">ຍອມຮັບ ເງື່ອນໄຂການໃຊ້ງານລະບົບ</span>
            </div>
            <Button
              variant="primary"
              type="submit"
              style={{ width: "100%" }}
              disabled={!agree || !password || !identifier}
              onClick={handleLogin}
            >
              Login
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
