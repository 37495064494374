import axios from "axios";
import { API } from "../../constants";
import { toast } from "react-toastify";
export const qcLoginFacebookPost = async (accountId, token) => {
  try {
    const url = `${API}/qc/login/${accountId}`;
    const res = await axios.post(url, null, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return { error: true };
  }
};
