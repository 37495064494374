import React from "react";
import TableComponent from "../../components/TableComponent";

export default function LogPage() {
  return (
    <div>
      <TableComponent>
        <thead>
          <tr>
            <th>No.</th>
            <th>Image</th>
            <th>Name</th>
            <th>mail</th>
            <th>status</th>
            <th>last_active</th>
            <th>type</th>
            <th>tool</th>
          </tr>
        </thead>
        <tbody></tbody>
      </TableComponent>
    </div>
  );
}
