import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Form, Button, Card, ListGroup, Breadcrumb } from "react-bootstrap";

// data
// eslint-disable-next-line
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
import { useProvider } from "../../providers";
import { accountPost } from "../../services/v1/account.service";
import AcountForm from "../../components/form/AcountForm";

export default function AccountCreatePage() {
  const navigate = useNavigate();
  // state ----------------------------------------------------------------------------
 
  // provider ----------------------------------------------------------------------------
  const { token } = useProvider();

  // useEffect ----------------------------------------------------------------------------

  // function ----------------------------------------------------------------------------
  const handleCreate = async (body) => {
    const data = await accountPost(body, token);
    if (!data?.error) {
      navigate("/account", { replace: true });
    }
  };
  return (
    <div style={{ padding: 20 }}>
       <Breadcrumb>
          <Breadcrumb.Item >มินเนี่ยน</Breadcrumb.Item>
          <Breadcrumb.Item active>เพี่มมินเนี่ยน</Breadcrumb.Item>
        </Breadcrumb>
      <AcountForm onSubmit={handleCreate}/>
    </div>
  );
}
