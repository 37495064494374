import React, { useEffect, useState } from "react";
import { Card, Button, Form, FloatingLabel } from "react-bootstrap";
import { FaMapPin, FaSave } from "react-icons/fa";
import UploadImageFreeImageHost from "../upload/UploadImageFreeImageHost";
const defaultCallback = () => {};
export default function HostelForm({
  onSubmit,
  defaultData,
  callback = defaultCallback,
}) {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    address: "",
    location: {},
    images: [],
    // image_id: "",
    hostelType: "",
    phone: "",
    ...defaultData,
  });
  const [invalidFormData, setInvalidFormData] = useState();
  // useEffect
  useEffect(() => {
    setInvalidFormData();
    console.log(formData?.location?.lat);
    let isInvalid = false;
    if (
      formData?.location?.lat > 90 ||
      formData?.location?.lat < -90 ||
      formData?.location?.lat == null ||
      formData?.location?.lat == undefined ||
      formData?.location?.lat == ""
    ) {
      setInvalidFormData((prev) => ({
        ...prev,
        location: { ...prev?.location, lat: true },
      }));
      isInvalid = true;
    }
    if (
      formData?.location?.lon > 180 ||
      formData?.location?.lon < -180 ||
      formData?.location?.lon == null ||
      formData?.location?.lon == undefined ||
      formData?.location?.lon == ""
    ) {
      setInvalidFormData((prev) => ({
        ...prev,
        location: { ...prev?.location, lon: true },
      }));
      isInvalid = true;
    }
    if (
      formData?.hostelType == null ||
      formData?.hostelType == undefined ||
      formData?.hostelType == ""
    ) {
      setInvalidFormData((prev) => ({
        ...prev,
        hostelType: true,
      }));
      isInvalid = true;
    }
    //
    if (!isInvalid) {
      setInvalidFormData();
    }
  }, [formData]);
  // function
  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(formData).then((e) => callback(e));
    setIsLoading(false);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: 10,
        flexDirection: "column",
      }}
    >
      <Card
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          gap: 10,
        }}
      >
        <div>
          <Card style={{ width: 120, height: 120 }}>
            <img
              src={formData?.imageProfile}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Card>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {/* 3434 */}
          <Button size="sm">
            <UploadImageFreeImageHost
              callback={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  imageProfile: e.image.display_url,
                }));
              }}
            >
              ເລືອກຮູບໂປຣຟາຍທີ່ພັກ
            </UploadImageFreeImageHost>
          </Button>

          <Button size="sm">
            <UploadImageFreeImageHost
              callback={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  images: [...prev.images, e.image.display_url],
                }));
              }}
            >
              ອັບໂຫຼດຮູບ
            </UploadImageFreeImageHost>
          </Button>
          <div>
            ຮູບໃນຄັງທັງໝົດ (
            <span style={{ color: "blue", textDecoration: "underline" }}>
              90 ຮູບ
            </span>
            )
          </div>
        </div>
      </Card>
      <Form.Group>
        <Form.Label>ຊື່ສະຖານທີ່ພັກ</Form.Label>
        <Form.Control
          placeholder="ຊື່..."
          value={formData?.name}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, name: e.target.value }))
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          ປະເພດ<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Select
          value={formData?.hostelType}
          isInvalid={invalidFormData?.hostelType}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, hostelType: e.target.value }))
          }
        >
          <option value="">ເລືອກປະເພດທີ່ພັກ</option>
          <option value="HOSTEL">ໂຮງແຮມ</option>
          <option value="APARTMENT">ຫ້ອງແຖວ & ອາພາດເມັນ</option>
          <option value="GUESTHOUSE">ບ້ານພັກ</option>
          <option value="HOUSE">ເຮືອນເຊົ່າ</option>
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>ເບີໂທ</Form.Label>
        <Form.Control
          placeholder="020 9*** ****"
          value={formData?.phone}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, phone: e.target.value }))
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>ລາຍລະອຽດ</Form.Label>
        <Form.Control
          as="textarea"
          value={formData?.description}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, description: e.target.value }))
          }
          placeholder="ລາຍລະອຽດ..."
          style={{ height: "100px" }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>ບ້ານ, ເມືອງ, ແຂວງ</Form.Label>
        <div style={{ display: "flex", gap: 10 }}>
          <Form.Select disabled>
            <option>ບ້ານ</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          <Form.Select disabled>
            <option>ເມືອງ</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          <Form.Select disabled>
            <option>ແຂວງ</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          ພິກັດ<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <div style={{ display: "flex", gap: 10 }}>
          <Form.Control
            type="number"
            onWheel={(e) => e.target.blur()}
            placeholder="lat: 18.xxxx"
            value={formData?.location?.lat}
            isInvalid={invalidFormData?.location?.lat}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                location: {
                  ...prev.location,
                  lat: e.target.value,
                },
              }))
            }
          />
          <Form.Control
            type="number"
            placeholder="lon: 102.xxxx"
            onWheel={(e) => e.target.blur()}
            value={formData?.location?.lon}
            isInvalid={invalidFormData?.location?.lon}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                location: {
                  ...prev.location,
                  lon: e.target.value,
                },
              }))
            }
          />
          <Button style={{ minWidth: 100 }} disabled>
            <FaMapPin /> ບ່ອນນີ້
          </Button>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Check type="checkbox" checked={true} label="ເປີດເຜີຍ" disabled />
      </Form.Group>
      <Button
        variant="primary"
        onClick={handleSubmit}
        disabled={isLoading || invalidFormData}
      >
        <FaSave /> ບັນທຶກ
      </Button>
    </div>
  );
}
