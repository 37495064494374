import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Form, Button, Card, ListGroup, Breadcrumb } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useProvider } from "../../providers";
import AddHostelPopup from "../../components/popup/AddHostelPopup";
import { COLOR } from "../../constants";
import {
  accountDelete,
  accountPut,
  accountsGet,
} from "../../services/v1/account.service";
import gmailImage from "../../assets/gmail-image.jpg";
// data
// eslint-disable-next-line
import { faker } from "@faker-js/faker";
import moment from "moment/moment";
import { qcLoginFacebookPost } from "../../services/v1/qc.service";

// icon
import { GrPowerReset } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import { MdOutlineAdd } from "react-icons/md";
import { MdGroupAdd } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { BiSolidCommentDetail } from "react-icons/bi";
import { FaShare } from "react-icons/fa6";
import { FaClipboardCheck } from "react-icons/fa";

// image
import imageEmptyProfile from "../../assets/empty-profile.webp";
import ConfirmDeletePopup from "../../components/popup/ConfirmDeletePopup";

export default function KeywordPage() {
  const navigate = useNavigate();
  // state
  const [popup, setPopup] = useState();
  const [selectAccount, setSelectAccount] = useState();
  const [accounts, setAccounts] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [filterStatusInput, setFilterStatusInput] = useState("");
  const [filterTypeInput, setFilterTypeInput] = useState("");
  // provider ----------------------------------------------------------------------------
  const {} = useProvider();
  const { token } = useProvider();

  // useEffect ----------------------------------------------------------------------------
  useEffect(() => {
    FetchAcountsData();
  }, []);

  // function ----------------------------------------------------------------------------
  const FetchAcountsData = async () => {
    let findBy = "?";
    if (searchInput) findBy += "search=" + searchInput + "&";
    if (filterStatusInput) findBy += "status=" + filterStatusInput + "&";
    if (filterTypeInput) findBy += "type=" + filterTypeInput + "&";
    const data = await accountsGet(findBy, token);
    if (!data?.error) {
      setAccounts(data);
    }
  };
  const handleLoginFacebook = async (accountId) => {
    qcLoginFacebookPost(accountId, token);
  };
  const handleDelete = async () => {
    await accountDelete(selectAccount?._id, token);
    await FetchAcountsData();
    setSelectAccount();
    setPopup();
  };
  const handleUpdateAccount = async (accountId, status) => {
    await accountPut(accountId, { status: status }, token);
    await FetchAcountsData();
  };
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 20,
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item active>keyword</Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}
        >
          <Card>
            <Card.Header>ໝວດສົ່ງເສີມການຂາຍ</Card.Header>
            <Card.Body>
              {[
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
              ].map((e, i) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px dashed #000",
                    padding: "10px 0",
                  }}
                  key={i}
                >
                  <span>{e?.title}</span>
                  <span>{e?.value}</span>
                </div>
              ))}
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>ໝວດສົ່ງເສີມການຂາຍ</Card.Header>
            <Card.Body>
              {[
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
              ].map((e, i) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px dashed #000",
                    padding: "10px 0",
                  }}
                  key={i}
                >
                  <span>{e?.title}</span>
                  <span>{e?.value}</span>
                </div>
              ))}
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>ໝວດສົ່ງເສີມການຂາຍ</Card.Header>
            <Card.Body>
              {[
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
                {
                  title: "ຂາຍ",
                  value: "900k",
                },
              ].map((e, i) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px dashed #000",
                    padding: "10px 0",
                  }}
                  key={i}
                >
                  <span>{e?.title}</span>
                  <span>{e?.value}</span>
                </div>
              ))}
            </Card.Body>
          </Card>
        </div>
      </div>
      {/* popup */}
    </>
  );
}

const IconGrStyle = styled("div")({
  path: {
    stroke: "#fff",
  },
});
const CardItemCompoment = styled(Card)({
  display: "flex",
  flexDirection: "row",
  gap: 10,
  padding: 10,
  cursor: "pointer",
  boxShadow: "3px 3px 10px 1px rgba(0,0,0,0.1)",
  ["&:hover"]: {
    backgroundColor: "rgba(26,57,80,0.1)",
    outline: `1px solid ${COLOR.PRIMARY}`,
  },
});
