import React, { useState } from "react";
import Box from "../components/Box";
import { Outlet } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Navigate } from "react-router-dom";
import { COLOR } from "../constants";
import packetJson from "../../package.json";
// icons
import {
  BsFillBarChartFill,
  BsFillPeopleFill,
  BsUiChecks,
} from "react-icons/bs";
import { FaMapMarkedAlt, FaHotel } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { MdSpaceDashboard } from "react-icons/md";
import { HiDocument } from "react-icons/hi";
import { FiLogOut } from "react-icons/fi";
import { useProvider } from "../providers";
import { GiBullyMinion } from "react-icons/gi";
import { PiPlantFill } from "react-icons/pi";
import { MdTimer } from "react-icons/md";
import { BiNetworkChart } from "react-icons/bi";
import { VscSymbolKeyword } from "react-icons/vsc";

import imageMinion1 from "../assets/minion-image-1.png";
import imageMinion2 from "../assets/minion-image-2.png";

export default function MainLayout({ children }) {
  const navigate = useNavigate();
  const pathname = window.location.pathname.split("/");
  // provider
  const { logout } = useProvider();
  return (
    <Box
      style={{
        // marginLeft: { md: 65 },

        height: "100dvh",
        display: "grid",
        gridTemplateColumns: "auto 1fr",
      }}
    >
      <div style={{ display: "flex", height: "100%", overflowY: "auto" }}>
        <Sidebar>
          <div
            style={{
              width: "100%",
              height: 150,
              color: COLOR?.PRIMARY,
              fontSize: 28,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            Minion
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 10,
                fontSize: 12,
              }}
            >
              V{packetJson?.version}
            </div>
          </div>
          <div
            style={{
              padding: "10px 20px",
              fontWeight: "bold",
              fontSize: 18,
              color: COLOR?.PRIMARY,
            }}
          >
            จัดการ
          </div>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: disabled
                      ? COLOR?.MUTED
                      : active
                      ? COLOR?.WHITE
                      : COLOR?.PRIMARY,
                    backgroundColor: active ? COLOR?.PRIMARY : undefined,
                  };
              },
            }}
          >
            <MenuItem
              active={pathname?.[1] == "account"}
              onClick={() => navigate("/account")}
              icon={<GiBullyMinion />}
            >
              มินเนี่ยน
            </MenuItem>

            <MenuItem
              icon={<PiPlantFill />}
              active={pathname?.[1] == "map"}
              onClick={() => navigate("/map")}
            >
              ทำงาน
            </MenuItem>
            <MenuItem
              icon={<MdTimer />}
              active={pathname?.[1] == "trigger"}
              onClick={() => navigate("/trigger")}
            >
              ตั้งเวลา
            </MenuItem>
            <MenuItem
              icon={<BsUiChecks />}
              active={pathname?.[1] == "doc"}
              onClick={() => navigate("/doc")}
              disabled
            >
              คิวซี (QC)
            </MenuItem>
            <MenuItem
              icon={<BiNetworkChart />}
              active={pathname?.[1] == "work"}
              onClick={() => navigate("/work")}
            >
              work
            </MenuItem>
            <MenuItem
              icon={<VscSymbolKeyword />}
              active={pathname?.[1] == "keyword"}
              onClick={() => navigate("/keyword")}
            >
              keyword
            </MenuItem>
          </Menu>
          <div
            style={{
              padding: "10px 20px",
              fontWeight: "bold",
              fontSize: 18,
              color: COLOR?.PRIMARY,
            }}
          >
            จัดการรายงาน
          </div>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: disabled
                      ? COLOR?.MUTED
                      : active
                      ? COLOR?.WHITE
                      : COLOR?.PRIMARY,
                    backgroundColor: active ? COLOR?.PRIMARY : undefined,
                  };
              },
            }}
          >
            <SubMenu
              defaultOpen={false}
              label="รายงาน"
              icon={<BsFillBarChartFill />}
            >
              <MenuItem disabled>รายงานการเงิน</MenuItem>
              <MenuItem disabled>รายงานระบบ</MenuItem>
            </SubMenu>
            <MenuItem disabled icon={<MdSpaceDashboard />}>
              Dashboard
            </MenuItem>
          </Menu>
          <div
            style={{
              padding: "10px 20px",
              fontWeight: "bold",
              fontSize: 18,
              color: COLOR?.PRIMARY,
            }}
          >
            ตั้งค่าระบบ
          </div>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: disabled
                      ? COLOR?.MUTED
                      : active
                      ? COLOR?.WHITE
                      : COLOR?.PRIMARY,
                    backgroundColor: active ? COLOR?.PRIMARY : undefined,
                  };
              },
            }}
          >
            <MenuItem disabled icon={<BsFillPeopleFill />}>
              ประวัติการใช้งาน
            </MenuItem>
            <MenuItem disabled icon={<IoSettingsSharp />}>
              ตั้งค่า
            </MenuItem>
            <MenuItem icon={<FiLogOut />} onClick={logout}>
              ออกจากระบบ
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <NavbarComponent />
        </div>
        <div
          style={{
            flex: 1,
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <Outlet />
          </div>
        </div>
      </div>
      {/* <img
        src={imageMinion2}
        alt="minion"
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: 450,
          zIndex: 9999,
        }}
      /> */}
      {/* <img
        src={imageMinion1}
        alt="minion"
        style={{
          position: "fixed",
          bottom: 0,
          left: 20,
          width: 150,
          zIndex: 9999,
        }}
      /> */}
    </Box>
  );
}

function NavbarComponent() {
  const navigate = useNavigate();
  // state ----------------------------------------------------------------------------
  const [offcanvas, setOffcanvas] = useState(false);

  const handleSelect = (to) => {
    navigate(to);
    setOffcanvas(false);
  };
  return (
    <Navbar key={"lg"} expand={"lg"} className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src="https://img.shields.io/badge/Linux-231f20?&logo=linux&logoColor=white"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-lg`}
          onClick={() => setOffcanvas(true)}
        />
        <Navbar.Offcanvas
          show={offcanvas}
          id={`offcanvasNavbar-expand-lg}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header
            closeButton={() => setOffcanvas(false)}
            onClick={() => setOffcanvas(false)}
          >
            {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              Offcanvas
            </Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link onClick={() => handleSelect("/logout")}>
                logout
              </Nav.Link>

              {/* <NavDropdown
                title="Dropdown"
                id={`offcanvasNavbarDropdown-expand-lg`}
              >
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            {/* <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Search</Button>
            </Form> */}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
