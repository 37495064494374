import { useState, useEffect } from "react";

export const BillContext = () => {
  const [isBillsLoading, setIsBillsLoading] = useState(false);
  const [bills, setBills] = useState([]);
  const [isBillSelectLoading, setIsBillSelectLoading] = useState(false);
  const [billSelect, setBillSelect] = useState();

  return {
    isBillsLoading,
    setIsBillsLoading,
    bills,
    setBills,
    isBillSelectLoading,
    setIsBillSelectLoading,
    billSelect,
    setBillSelect,
    // function ----------------------------------------------------------------------------
  };
};
