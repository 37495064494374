import React, { useEffect, useState } from "react";
import { Card, Button, Form, FloatingLabel, CardHeader } from "react-bootstrap";
import { FaMapPin, FaSave } from "react-icons/fa";
import UploadImageFreeImageHost from "../upload/UploadImageFreeImageHost";
import styled from "styled-components";
import { COLOR } from "../../constants";
import { PiVirusDuotone } from "react-icons/pi";
import DraggableComponent from "../DraggableComponent";
import FileDropzone from "../FileDropzone";
const defaultCallback = () => {};
export default function AcountForm({
  onSubmit,
  defaultData,
  callback = defaultCallback,
}) {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    address: "",
    location: {},
    images: [],
    // image_id: "",
    hostelType: "",
    phone: "",
    ...defaultData,
  });
  // useEffect
  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...defaultData }));
  }, [defaultData]);

  // function
  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(formData).then((e) => callback(e));
    setIsLoading(false);
  };
  return (
    <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: 20 }}>
      <Card
        style={{
          maxWidth: 600,
          minWidth: 400,
        }}
      >
        <Card.Header style={{ backgroundColor: COLOR.PRIMARY, color: "white" }}>
          <PiVirusDuotone />
          ເພີ່ມ account
        </Card.Header>
        <Card.Body
          style={{
            padding: 20,
            display: "flex",
            justifyContent: "center",
            gap: 10,
            flexDirection: "column",
          }}
        >
          <Group>
            <Form.Label>fullname</Form.Label>
            <Form.Control
              value={formData?.fullname}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, fullname: e.target.value }))
              }
            />
          </Group>
          <Group>
            <Form.Label>ชื่อ</Form.Label>
            <Form.Control
              value={formData?.firstname}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, firstname: e.target.value }))
              }
            />
          </Group>
          <Group>
            <Form.Label>นามสกุน</Form.Label>
            <Form.Control
              value={formData?.lastname}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, lastname: e.target.value }))
              }
            />
          </Group>
          <Group>
            <Form.Label>email</Form.Label>
            <Form.Control
              value={formData?.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </Group>
          <Group>
            <Form.Label>identifier</Form.Label>
            <Form.Control
              value={formData?.identifier}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, identifier: e.target.value }))
              }
            />
          </Group>
          <Group>
            <Form.Label>password</Form.Label>
            <Form.Control
              value={formData?.password}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, password: e.target.value }))
              }
            />
          </Group>
          <Group>
            <Form.Label>username</Form.Label>
            <Form.Control
              value={formData?.username}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, username: e.target.value }))
              }
            />
          </Group>
          <Group>
            <Form.Label>type</Form.Label>
            <Form.Select
              value={formData?.type}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, type: e.target.value }))
              }
            >
              <option value="">ເລືອກ type</option>
              <option value="facebook">facebook</option>
              <option value="google">google</option>
            </Form.Select>
            {/* <Form.Control
          value={formData?.type}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, type: e.target.value }))
          }
        /> */}
          </Group>
          <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
            <FaSave /> ບັນທຶກ
          </Button>
        </Card.Body>
      </Card>
      <FileDropzone />
    </div>
  );
}

const Group = styled("div")({
  display: "grid",
  gridTemplateColumns: "80px 1fr",
  gap: 10,
});
