import React, { useState, useRef, useEffect } from "react";
import { useProvider } from "../../providers";
import { Breadcrumb, Button, Card } from "react-bootstrap";
import { ordersGet } from "../../services/v1/order.service";

export default function MapPage() {
  // state
  const [ordersData, setOrdersData] = useState();
  // provider

  // useEffect
  useEffect(() => {
    getData();
  }, []);
  // function
  const getData = async () => {
    try {
      const data = await ordersGet("?p=accountId");
      setOrdersData(data);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
          Library
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Data</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }} />
        <Button>สั่งงาน</Button>
      </div>

      <div
        style={{
          padding: "10px 0",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        {ordersData?.map((e) => (
          <Card
            style={{
              width: "100%",
              padding: 10,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <div>
                ชื่อ: {e?.accountId?.firstname} {e?.accountId?.lastname}
              </div>
              <div>ประเทศ: {e?.accountId?.vpn}</div>
              <div>ประเภท: {e?.type}</div>
              <div>งาน: {e?.job}</div>
              <div></div>
            </div>
            <div style={{ flex: 1 }} />
            <div>
              <Button size="sm">ลบ</Button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}
