import React from "react";
// import { Outlet } from "react-router-dom";

// page
import MapPage from "../pages/map/MapPage";

// eslint-disable-next-line
export default {
  path: "/map",
  element: <MapPage />,
  // children: [
  //   {
  //     path: "settingTable/:id",
  //     element: <SettingTable />,
  //   },
  // ],
};
