import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Form, Button, Card, ListGroup, Breadcrumb } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useProvider } from "../../providers";
import AddHostelPopup from "../../components/popup/AddHostelPopup";
import { COLOR } from "../../constants";
import {
  accountDelete,
  accountPut,
  accountsGet,
} from "../../services/v1/account.service";
import gmailImage from "../../assets/gmail-image.jpg";
// data
// eslint-disable-next-line
import moment from "moment/moment";
import { qcLoginFacebookPost } from "../../services/v1/qc.service";

// icon
import { GrPowerReset } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import { MdOutlineAdd } from "react-icons/md";
import { MdGroupAdd } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { BiSolidCommentDetail } from "react-icons/bi";
import { FaShare } from "react-icons/fa6";
import { FaClipboardCheck } from "react-icons/fa";

// image
import imageEmptyProfile from "../../assets/empty-profile.webp";
import ConfirmDeletePopup from "../../components/popup/ConfirmDeletePopup";
import { billsGet } from "../../services/v1/bill.service";
import TableComponent from "../../components/TableComponent";

export default function WorkPage() {
  const navigate = useNavigate();
  // state
  const [popup, setPopup] = useState();
  const [selectAccount, setSelectAccount] = useState();
  const [bills, setBills] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [filterStatusInput, setFilterStatusInput] = useState("");
  const [filterTypeInput, setFilterTypeInput] = useState("");
  // provider ----------------------------------------------------------------------------
  const {} = useProvider();
  const { token } = useProvider();

  // useEffect ----------------------------------------------------------------------------
  useEffect(() => {
    FetchAcountsData();
  }, []);

  // function ----------------------------------------------------------------------------
  const FetchAcountsData = async () => {
    let findBy = "?";
    if (searchInput) findBy += "search=" + searchInput + "&";
    if (filterStatusInput) findBy += "status=" + filterStatusInput + "&";
    if (filterTypeInput) findBy += "type=" + filterTypeInput + "&";
    const data = await billsGet(findBy, token);
    if (!data?.error) {
      setBills(data);
    }
  };
  const handleLoginFacebook = async (accountId) => {
    qcLoginFacebookPost(accountId, token);
  };
  const handleDelete = async () => {
    await accountDelete(selectAccount?._id, token);
    await FetchAcountsData();
    setSelectAccount();
    setPopup();
  };
  const handleUpdateAccount = async (accountId, status) => {
    await accountPut(accountId, { status: status }, token);
    await FetchAcountsData();
  };
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 20,
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item active>งาร</Breadcrumb.Item>
        </Breadcrumb>
        <Card
          style={{
            padding: 10,
            display: "flex",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          {[
            {
              icon: <MdGroupAdd style={{ width: 40, height: 40 }} />,
              title: "ເພີ່ມໝູ່ auto",
            },
            {
              icon: <AiFillLike style={{ width: 40, height: 40 }} />,
              title: "ກົດໄລໂພສ",
            },
            {
              icon: <AiFillLike style={{ width: 40, height: 40 }} />,
              title: "ກົດໄລເພຈ",
            },
            {
              icon: <BiSolidCommentDetail style={{ width: 40, height: 40 }} />,
              title: "ຄອມເມັນໂພສ",
            },
            {
              icon: <FaShare style={{ width: 40, height: 40 }} />,
              title: "ກົດແຊຣໂພສ",
            },
            {
              icon: <FaClipboardCheck style={{ width: 40, height: 40 }} />,
              title: "ແອກຕີບທົ່ວໄປ",
            },
          ].map((e) => (
            <Card
              style={{ padding: 10, alignItems: "center", gap: 5, width: 140 }}
            >
              <div>{e?.icon}</div>
              <div style={{ fontWeight: 700, fontSize: 18 }}>{e?.title}</div>
              <Button size="sm" style={{ width: "100%" }}>
                ນຳໃຊ້
              </Button>
            </Card>
          ))}
        </Card>
        <Card
          style={{
            padding: 10,
            display: "flex",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <div style={{ display: "flex", gap: 10 }}>
            <Form.Control
              type="text"
              placeholder="ຄົ້ນຫາ..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />

            <Button variant="primary" onClick={() => FetchAcountsData()}>
              <FaSearch />
            </Button>
          </div>
          <Form.Select
            style={{ width: 150 }}
            value={filterStatusInput}
            onChange={(e) => setFilterStatusInput(e.target.value)}
          >
            <option value="">status</option>
            <option value="WAIT_QC">WAIT_QC</option>
            <option value="STRONG">STRONG</option>
            <option value="DOWN">DOWN</option>
          </Form.Select>
          <Form.Select
            style={{ width: 150 }}
            value={filterTypeInput}
            onChange={(e) => setFilterTypeInput(e.target.value)}
          >
            <option value="">type</option>
            <option value="facebook">facebook</option>
            <option value="google">google</option>
          </Form.Select>

          <Button
            variant="primary"
            onClick={() => {
              setSearchInput("");
              setFilterTypeInput("");
              setFilterStatusInput("");
            }}
          >
            <IconGrStyle>
              <GrPowerReset />
            </IconGrStyle>
          </Button>
          <div style={{ flex: 1 }} />
          <Button onClick={() => navigate("/work/create")}>
            <MdOutlineAdd /> เพี่มมินเนี่ยน
          </Button>
        </Card>
        <TableComponent>
          <thead>
            <tr>
              <th>No.</th>
              <th>name</th>
              <th>type</th>
            </tr>
          </thead>
          <tbody>
            {bills?.map((e, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>
                  <span
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => navigate(`/work/datail/${e?._id}`)}
                  >
                    {e?.name}
                  </span>
                </td>
                <td>{e?.type}</td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </div>
      {/* popup */}
      <AddHostelPopup open={popup?.AddHostelPopup} onClose={() => setPopup()} />
      <ConfirmDeletePopup
        open={popup?.ConfirmDeletePopup}
        onClose={() => setPopup()}
        onSubmit={handleDelete}
      />
    </>
  );
}

const IconGrStyle = styled("div")({
  path: {
    stroke: "#fff",
  },
});
const CardItemCompoment = styled(Card)({
  display: "flex",
  flexDirection: "row",
  gap: 10,
  padding: 10,
  cursor: "pointer",
  boxShadow: "3px 3px 10px 1px rgba(0,0,0,0.1)",
  ["&:hover"]: {
    backgroundColor: "rgba(26,57,80,0.1)",
    outline: `1px solid ${COLOR.PRIMARY}`,
  },
});
