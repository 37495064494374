import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../constants/color";

export default function TableComponent({ children }) {
  return <Table>{children}</Table>;
}

const Table = styled("table")({
  width: "100%",
  ["td,th"]: {
    padding: 8,
  },
  ["tr:nth-child(even)"]: { backgroundColor: "#f2f2f2" },
  ["tr:hover"]: { backgroundColor: "#949699" },
  th: {
    paddingTop: 12,
    paddingBottom: 12,
    textAlign: "left",
    backgroundColor: PRIMARY,
    color: "#ffffff",
  },
});
