import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useProvider } from "../providers";
import decodeJwt from "jwt-decode";

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const { token, isLogin, logout } = useProvider();
  useEffect(() => {
    if (!token) return console.log("EMPTY TOKEN");
    const decodedToken = decodeJwt(token);
    const expirationTime = decodedToken.exp * 1000;
    const now = new Date().getTime();
    const delay = expirationTime - now;
    function tokenExpired() {
      console.log("Token has expired!");
      logout();
      // navigate("/", { replace: true });
    }
    setTimeout(tokenExpired, delay);
  }, [token]);

  if (isLogin) {
    return children;
  } else {
    return <Navigate to="/auth/login" />;
  }
}

export default PrivateRoute;
